import parseLoggedInUser from 'utils/auth/parseLoggedInUser';
import LogRocket from 'logrocket';
import types from './types';

const {
  AUTH_CALLBACK, AUTH_INIT,
  LOGIN, LOGIN_SUCCESS, LOGIN_FAIL,
  REFRESH, REFRESH_SUCCESS, REFRESH_FAIL,
  RESEND, RESEND_SUCCESS, RESEND_FAIL, RESEND_CLEAR,
  VERIFY, VERIFY_SUCCESS, VERIFY_FAIL,
  LOAD_OWNERS, LOAD_OWNERS_SUCCESS, LOAD_OWNERS_FAIL,
  SEND_REQUEST, SEND_REQUEST_SUCCESS, SEND_REQUEST_FAIL,
  VALIDATE_MOBILE, VALIDATE_MOBILE_SUCCESS, VALIDATE_MOBILE_FAIL,
  SELECT_2FA_DELIVERY_METHOD,
  SET_USER, CLEAN_UP,
} = types;

const initialState = {
  loading: false,
  loaded: false,
  loggingIn: false,
  loggedIn: false,
  verifying: false,
  verified: false,
  resending: false,
  resent: false,
  twoFaUsername: null,
  twoFactorRequestId: null,
  twoFactorRequestExpiry: null,
  twoFactorRequestGenerated: false,
  twoFactorDeliveryMethod: null,
  trustedDevice: null,
  validatingMobile: false,
  validatedMobile: false,
  phone: null,
  mobileValidationError: null,
  token: null,
  user: null,
  admin: null,
  owners: [],
  provider: null,
  error: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loggingIn: true,
        loggedIn: false,
        user: null,
        auth2faOptions: null,
        admin: null,
        owners: [],
        error: {},
      };
    case REFRESH_SUCCESS:
    case LOGIN_SUCCESS:
    {
      const { result: { id, twoFactorRequestId, twoFactorRequestExpiry, twoFactorRequestGenerated, ttl, used2FA, user, username, auth2faOptions } } = action;
      const user2Fa = twoFactorRequestId !== undefined;

      if (user2Fa || auth2faOptions) {
        return {
          ...state,
          twoFaUsername: username,
          twoFactorRequestId,
          twoFactorRequestExpiry,
          twoFactorRequestGenerated,
          auth2faOptions,
          loggingIn: false,
          loggedIn: false,
          // Start the timer
          resent: true,
        };
      }
      const userState = parseLoggedInUser(user);
      if (user?.id?.toString()) {
        LogRocket.identify(user.id.toString());
      }
      return {
        ...state,
        token: id,
        ttl,
        loggingIn: false,
        loggedIn: true,
        used2FA,
        ...userState,
      };
    }
    case LOGIN_FAIL:
    case REFRESH_FAIL:
      return {
        ...state,
        loggingIn: false,
        loggedIn: false,
        token: null,
        auth2faOptions: null,
        user: null,
        admin: null,
        error: action.error,
      };
    case REFRESH:
      return {
        ...state,
      };
    case RESEND:
      return {
        resending: true,
        resent: false,
        ...state,
      };
    case RESEND_SUCCESS:
      return {
        ...state,
        resending: false,
        resent: true,
        twoFactorRequestId: action.result.twoFactorRequestId,
        twoFactorRequestExpiry: action.result.twoFactorRequestExpiry,
        twoFactorRequestGenerated: action.result.twoFactorRequestGenerated,
      };
    case RESEND_FAIL:
      return {
        ...state,
        resending: false,
        resent: false,
        error: action.error,
      };
    case RESEND_CLEAR:
      return {
        ...state,
        resending: false,
        resent: false,
        error: {},
      };
    case VERIFY:
      return {
        ...state,
        verifying: true,
        verified: false,
      };
    case VERIFY_SUCCESS: {
      const { id, trustedDevice, ttl, user } = action.result;
      const userState = parseLoggedInUser(user);
      return {
        ...state,
        token: id,
        ttl,
        verifying: false,
        verified: true,
        // Remove both twoFa fields after it has been verified as it is useless after this.
        auth2faOptions: null,
        twoFactorRequestId: null,
        twoFactorRequestGenerated: false,
        twoFaUsername: null,
        trustedDevice,
        ...userState,
      };
    }
    case VERIFY_FAIL: {
      return {
        ...state,
        verifying: false,
        verified: false,
        error: action.error,
      };
    }
    case LOAD_OWNERS:
      return {
        ...state,
        loading: true,
        loaded: false,
        owners: [],
        error: {},
      };
    case LOAD_OWNERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        owners: action.result,
      };
    }
    case LOAD_OWNERS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case AUTH_INIT:
      // Redirect whole browser to the authentication service
      window.location.replace(action.result.location);
      return {
        ...state,
      };
    case AUTH_CALLBACK: {
      const { result: { id, twoFactorRequestId, twoFactorRequestExpiry, twoFactorRequestGenerated, user, username, auth2faOptions } } = action;
      const user2Fa = twoFactorRequestId !== undefined;

      if (user?.id?.toString()) {
        LogRocket.identify(user.id.toString());
      }

      if (user2Fa || auth2faOptions) {
        return {
          ...state,
          twoFaUsername: username,
          twoFactorRequestId,
          twoFactorRequestExpiry,
          twoFactorRequestGenerated,
          auth2faOptions,
          loggingIn: false,
          loggedIn: false,
          resent: true,
        };
      }

      const userState = parseLoggedInUser(user);
      return {
        ...state,
        token: id,
        loggingIn: false,
        loggedIn: true,
        provider: 'google',
        ...userState,
      };
    }
    case SET_USER:
      return {
        ...state,
        user: action.user,
      };
    case SELECT_2FA_DELIVERY_METHOD:
      return {
        ...state,
        twoFactorDeliveryMethod: action.deliveryMethod,
      };
    case SEND_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: {},
      };
    case SEND_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case SEND_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case CLEAN_UP:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: {},
      };
    case VALIDATE_MOBILE: {
      return {
        ...state,
        validatingMobile: true,
        validatedMobile: false,
        mobileValidationError: null,
        phone: null,
      };
    }
    case VALIDATE_MOBILE_SUCCESS: {
      return {
        ...state,
        validatingMobile: false,
        validatedMobile: true,
        phone: action.result,
      };
    }
    case VALIDATE_MOBILE_FAIL:
      return {
        ...state,
        validatingMobile: false,
        validatedMobile: false,
        mobileValidationError: !!action.error,
      };
    default:
      return state;
  }
}
