import { add } from 'redux/modules/notifications';

export class Notification {
  constructor(titleId, messageId, type, notificationType, closeAction) {
    this.time = new Date();
    this.type = type;
    this.notificationType = notificationType;
    this.titleId = titleId;
    this.messageId = messageId;
    this.closeAction = closeAction;
  }

  isValid() {
    return (typeof this.messageId === 'string') && this.messageId !== '';
  }
}

// Handle incoming notifications from actionPromise
// This middleware will not change anything in state or action, but it will
// trigger a new create a new notification when found, and trigger a new action to store it
export default ({ dispatch }) => next => action => {
  const { type, types, notification, notifications } = action;

  // Handle multiple notifications
  if (Array.isArray(notifications) && Array.isArray(types)) {
    // For promises, there are multiple types and it's possible to assign a notification for each
    // Find the notification that matches current action's type
    const typeIndex = types.findIndex(t => t === type);
    const typeNotification = new Notification(null, notifications[typeIndex], type);
    if (typeNotification.isValid()) {
      dispatch(add(typeNotification));
    }
  }
  else {
    // Handle single notification
    const typeNotification = new Notification(null, notification, type);
    if (typeNotification.isValid()) {
      dispatch(add(typeNotification));
    }
  }

  next(action);
};
