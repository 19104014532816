import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Form, Grid, Label } from 'semantic-ui-react';
import ThemeLinkButton from 'components/Theme/ThemeLinkButton';

const formatTime = seconds => {
  // eg 1:30 (90), 0:30 (30)
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  // return formatted string to include leading 0 for seconds and minutes
  return `${minutes}:${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`;
};

const TwoFactorAuthForm = ({ isAdmin, twoFactorDeliveryMethod, twoFaRequestExpiry, onSubmit, onResend2FACode }) => {
  const { formatMessage } = useIntl();
  const subtitleId = isAdmin ? 'page.user.twoFactorAuth.subtitle.admin' : 'page.user.twoFactorAuth.subtitle.user';
  const [input2FaCode, setInput2FaCode] = useState(null);
  const [userRetries, setUserRetries] = useState(0);
  const [request2FACountdownRemaining, setRequest2FACountdownRemaining] = useState(0);
  const [timerInterval, setTimerInterval] = useState(null);

  const createTimer = () => {
    if (!timerInterval) {
      setTimerInterval(setInterval(() => {
        setRequest2FACountdownRemaining(oldCountdown => {
          const countdown = oldCountdown - 1;
          if (countdown === 0) {
            clearInterval(timerInterval);
          }

          return countdown;
        });
      }, 1000));
    }
  };

  useEffect(() => {
    if (!twoFaRequestExpiry) {
      return;
    }
    // get the seconds reminaing from twoFaRequestExpiry to NOW
    const remaining = Math.floor((new Date(twoFaRequestExpiry) - new Date()) / 1000);
    setRequest2FACountdownRemaining(remaining);
    createTimer();
  }, [twoFaRequestExpiry]);

  const handleInputChange = value => {
    setInput2FaCode(value);
  };

  const handleFormSubmit = () => {
    onSubmit(input2FaCode);
  };

  const handleResend2FACode = () => {
    setUserRetries(userRetries + 1);
    onResend2FACode();
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <Grid>
        <Grid.Row>
          <FormattedMessage id={subtitleId} />
        </Grid.Row>
        <Grid.Row>
          <Form.Input
            required
            id="twoFaCode"
            label={formatMessage({ id: 'page.user.twoFactorAuth.form.label.twoFaCode' })}
            style={{
              width: '100px',
            }}
            onChange={(e, { value }) => handleInputChange(value)}
          />
        </Grid.Row>
        <Grid.Row style={{ padding: 0 }} centered>
          <ThemeLinkButton
            label={
              formatMessage(
                request2FACountdownRemaining <= 0 ?
                  { id: 'page.user.twoFactorAuth.retry' }
                  : { id: 'page.user.twoFactorAuth.pending' },
                { value: formatTime(request2FACountdownRemaining) }
              )
            }
            onClick={handleResend2FACode}
            disabled={request2FACountdownRemaining > 0}
          />
        </Grid.Row>
        <Grid.Row centered>
          {userRetries > 0 && (
            <Label pointing="below" prompt>
              <FormattedMessage id={`page.user.twoFactorAuth.${twoFactorDeliveryMethod === 'sms' ? 'suggestionTryVoice' : 'suggestionTrySMS'}`} />
            </Label>
          )}
          <Button type="submit" color="green" size="large" disabled={!input2FaCode}>
            <FormattedMessage id="general.submit" />
          </Button>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

TwoFactorAuthForm.propTypes = {
  isAdmin: PropTypes.bool,
  twoFactorDeliveryMethod: PropTypes.string.isRequired,
  twoFaRequestExpiry: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onResend2FACode: PropTypes.func.isRequired,
};

TwoFactorAuthForm.defaultProps = {
  isAdmin: false,
};

export default TwoFactorAuthForm;
