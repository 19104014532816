import types from './types';

const { DISPLAY, HIDE } = types;

export function display(title, text, messageType, closeAction) {
  return { type: DISPLAY, title, text, messageType, closeAction };
}

export function hide() {
  return { type: HIDE };
}
