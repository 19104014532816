import types from './types';

const { DISPLAY, HIDE } = types;

const initialState = {
  title: null,
  text: null,
  messageType: null,
  visible: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DISPLAY:
      return {
        title: action.title,
        text: action.text,
        messageType: action.messageType,
        closeAction: action?.closeAction,
        visible: true,
      };
    case HIDE:
      return initialState;
    default:
      return state;
  }
}
