import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';

const Dialog = ({ open, type, title, message, onClose }) => {
  const getType = () => {
    switch (type) {
      case 'error': return { icon: 'exclamation triangle', color: '#db5959' };
      case 'warning': return { icon: 'warning circle', color: '#e39f30' };
      case 'info': return { icon: 'info circle', color: '#b8875a' };
      case 'success': return { icon: 'check circle', color: '#54b864' };
      default: return {};
    }
  };

  const errorMessage = () => {
    if (typeof message === 'object') {
      return (<FormattedMessage id={message?.message ?? ''} values={{ value: message?.value ?? '' }} />);
    }
    return message;
  };

  const dialogType = getType();
  const dialogMessage = errorMessage();

  return (
    <Modal open={open} onClose={onClose} size="mini" dimmer="inverted">
      <Modal.Content style={{ textAlign: 'center' }}>
        <Header icon as="h2" style={{ margin: '20px' }}>
          <Icon name={dialogType.icon} style={{ color: dialogType.color }} />
          {title}
        </Header>
        <Header as="h3" style={{ margin: '20px' }}>
          {dialogMessage}
        </Header>
      </Modal.Content>
      <Modal.Actions style={{ padding: '0', backgroundColor: dialogType.color }}>
        <Button
          fluid
          size="huge"
          style={{ color: '#ffffff', backgroundColor: dialogType.color, minHeight: '70px', margin: '0', borderRadius: '0' }}
          onClick={onClose}
        >
          <FormattedMessage id="general.dismiss" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

Dialog.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.oneOfType([
    // Mainly for displaying password validation errors.
    PropTypes.shape({
      message: PropTypes.string,
      value: PropTypes.number,
    }),
    // For the rest.
    PropTypes.string,
  ]),
  onClose: PropTypes.func.isRequired,
};

export default Dialog;
